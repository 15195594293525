import styled from "@emotion/styled";

type ListProps = {
  count: number;
  listWidth: number;
};

const makeRaws = (count: number, listWidth: number): string | undefined => {
  if (count === 4) {
    return `
      display: grid; 
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      @media (max-width: 1240px) {
      grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 1024px) {
      grid-template-columns: repeat(${count - 1}, 1fr);
      > :last-child {
       grid-column: 1 / -1;
       }
      }
       @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
       }
    `;
  }

  if (count === 3) {
    return `
      display: grid; 
      grid-template-columns: repeat(${count}, 1fr);
      gap: 16px;
      @media (max-width: 1024px) {
      grid-template-columns: repeat(${count - 1}, 1fr);
      > :last-child {
       grid-column: 1 / -1;
       }
      }
       @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
       }
    `;
  }

  if (count % 2 !== 0) {
    return `
      display: grid; 
      grid-template-columns: repeat( 2, 1fr);
      gap: 16px;
      
      > :last-child {
       grid-column: 1 / -1;
       }
      
       @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
       }
    `;
  }

  if (count % 2 === 0) {
    return `
      display: grid; 
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      @media (max-width: 1024px) {
      grid-template-columns: repeat(${count}, 1fr);
      }
       @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
       }
    `;
  }

  return undefined;
};

export const List = styled.ul<ListProps>`
  ${({ count, listWidth }) => makeRaws(count, listWidth)}
`;
