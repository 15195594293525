import React, { useRef, useEffect, useState } from "react";
import ServiceIncItem from "../ServiceIncItem";
import { List } from "./ServiceIncList.styled";
import { TServiceIncludesList } from "../../types/ServiceIncludes";

type ServiceIncListProps = { services: TServiceIncludesList };

const ServiceIncList: React.FC<ServiceIncListProps> = ({ services }) => {
  const listRef = useRef<HTMLUListElement | null>(null);
  const [listWidth, setListWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (listRef.current) {
      setListWidth(listRef.current.offsetWidth);
    }
  }, [services]);

  const effectiveListWidth = listWidth ?? 0;
  return (
    <List ref={listRef} count={services.length} listWidth={effectiveListWidth}>
      {services.map((service, index) => {
        return (
          <ServiceIncItem
            key={index}
            title={service.title}
            description={service?.description}
            icon={service.icon}
            full={services.length <= 1}
          />
        );
      })}
    </List>
  );
};

export default ServiceIncList;
