import React, { useEffect, useState } from "react";

import sprite from "../../icons/symbol-defs.svg";
import Button from "../Button";
import { TVideoExamples, TVideoExamplesList } from "../../types/VideoExamples";
import {
  CloseButton,
  GalleryContainer,
  GalleryItem,
  GalleryRow,
  ImageWrapper,
  ModalBackdrop,
  ModalContent,
  Overlay,
  PlayButton,
  PlaySvg,
  StyledImage,
  ToggleButton,
  VideoIframe,
  Title,
} from "./VideoGallery.styled";

const VideoGallery: React.FC<{ visibleData: TVideoExamplesList }> = ({
  visibleData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [galleryHeight, setGalleryHeight] = useState<number | string>("640px");
  const [transitionHeight, setTransitionHeight] = useState(true);

  const galleryRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerWidth < 768) {
        setGalleryHeight("2000px");
      } else {
        setGalleryHeight("640px");
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const openModal = (videoUrl: string) => {
    setCurrentVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setTransitionHeight(false);
    setIsModalOpen(false);
    setCurrentVideoUrl(null);
  };

  const toggleVisibility = () => {
    if (!isExpanded && galleryRef.current) {
      setGalleryHeight(`${galleryRef.current.scrollHeight}px`);
    } else {
      setGalleryHeight(window.innerWidth < 768 ? "2000px" : "640px");
    }
    setIsExpanded(!isExpanded);

    if (isExpanded) {
      setTimeout(() => {
        titleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 300);
    }
  };

  return (
    <div>
      <Title ref={titleRef}>Find inspiration in our videos creations</Title>
      <GalleryContainer
        ref={galleryRef}
        height={galleryHeight}
        transitionHeight={transitionHeight}
      >
        {visibleData.map((row: TVideoExamples[], rowIndex: number) => (
          <GalleryRow key={rowIndex}>
            {row.map((item: TVideoExamples, itemIndex: number) => (
              <GalleryItem key={itemIndex} span={row.length === 1 ? 1 : 0.5}>
                <ImageWrapper>
                  <StyledImage
                    src={item.image}
                    alt={`Image ${rowIndex}-${itemIndex}`}
                  />
                  <Overlay>
                    <PlayButton onClick={() => openModal(item.video)}>
                      <PlaySvg>
                        <use xlinkHref={`${sprite}#play`}></use>
                      </PlaySvg>
                    </PlayButton>
                  </Overlay>
                </ImageWrapper>
              </GalleryItem>
            ))}
          </GalleryRow>
        ))}
        <ToggleButton onClick={toggleVisibility}>
          <Button
            onClick={toggleVisibility}
            text={isExpanded ? "Show Less" : "Show More"}
            icon={isExpanded ? "arrow-top" : "arrow-down"}
            align="row-reverse"
            width={window.innerWidth < 768 ? "300px" : "525px"}
          />
        </ToggleButton>
      </GalleryContainer>

      {isModalOpen && (
        <ModalBackdrop onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>×</CloseButton>
            {currentVideoUrl && (
              <VideoIframe
                src={`${currentVideoUrl}?autoplay=1`}
                title="YouTube video player"
                allow="autoplay; encrypted-media"
              />
            )}
          </ModalContent>
        </ModalBackdrop>
      )}
    </div>
  );
};

export default VideoGallery;
