import styled from "@emotion/styled";
import {
  white,
  lavender,
  lightGray,
  d1,
  pink,
  black,
  yellow,
  c3,
} from "../../assets";
import { Link } from "react-router-dom";

type listType = {
  listType: "service" | "developments";
};

type linkType = {
  color: "yellow" | "pink";
};

export const CardWrap = styled.div<listType>`
  background-color: ${white};
  padding: 32px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ listType }) =>
    listType === "service"
      ? white
      : listType === "developments"
      ? lavender
      : "transparent"};
  @media (max-width: 1024px) {
    padding: 24px;
  }
  @media (max-width: 768px) {
    padding: 16px;
    max-width: 500px;
    margin: auto;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  min-height: 188px;
  border-radius: 12px;
  background-color: ${lightGray};
  margin-bottom: 24px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
`;

export const DescriptionText = styled.p<listType>`
  ${d1}
  color: ${({ listType }) => (listType === "service" ? black : white)};
  height: 100%;
  margin-bottom: 24px;
`;
export const CustomLink = styled(Link)<linkType>`
  ${c3}
  color: ${({ color }) => (color === "pink" ? pink : yellow)};
  margin: auto;
`;
