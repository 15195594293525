import React from "react";
import {
  ButtonPrimary,
  ButtonNormal,
  Icon,
  ButtonWrap,
  ButtonDashboard,
  ButtonAiGenerator,
} from "./Button.styled";
import sprite from "../../icons/symbol-defs.svg";

type ButtonProps = {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  withIcon?: boolean;
  text?: string;
  btnType?:
    | "primary"
    | "normal"
    | "dashboard"
    | "dashboard-nav"
    | "ai-generator";
  width?: string;
  margin?: string;
  disabled?: boolean;
  active?: boolean;
  icon?: string;
  color?: string;
  bgColor?: string;
  align?: string;
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  withIcon = true,
  btnType = "primary",
  width = "100%",
  text = "Add to Cart",
  margin = "16px",
  disabled = false,
  type = "button",
  active,
  color,
  icon,
  bgColor,
  align,
}) => {
  return (
    <ButtonWrap disabled={disabled} margin={margin}>
      {btnType === "primary" && (
        <ButtonPrimary
          type={type}
          onClick={onClick}
          btnType={btnType}
          width={width}
          withIcon={withIcon}
          disabled={disabled}
          active={active}
          bgColor={bgColor}
          align={align}
        >
          {withIcon &&
            !disabled &&
            (icon ? (
              <Icon
                width="24"
                height="24"
                btnType={btnType}
                active={active}
                icon={icon}
              >
                <use xlinkHref={`${sprite}#${icon}`} />
              </Icon>
            ) : (
              <Icon width="24" height="24">
                <use xlinkHref={`${sprite}#cartY`} />
              </Icon>
            ))}
          {withIcon &&
            disabled &&
            (icon ? (
              <Icon
                width="24"
                height="24"
                btnType={btnType}
                active={active}
                icon={icon}
              >
                <use xlinkHref={`${sprite}#${icon}`} />
              </Icon>
            ) : (
              <Icon width="24" height="24">
                <use xlinkHref={`${sprite}#cartW`}></use>
              </Icon>
            ))}

          {text}
        </ButtonPrimary>
      )}

      {btnType === "normal" && (
        <ButtonNormal
          onClick={onClick}
          btnType={btnType}
          width={width}
          withIcon={withIcon}
          disabled={disabled}
          active={active}
        >
          {withIcon && !disabled && (
            <Icon width="24" height="24">
              <use xlinkHref={`${sprite}#cartY`}></use>
            </Icon>
          )}
          {withIcon && disabled && (
            <Icon width="24" height="24">
              <use xlinkHref={`${sprite}#cartD`}></use>
            </Icon>
          )}

          {text}
        </ButtonNormal>
      )}

      {(btnType === "dashboard" || btnType === "dashboard-nav") && (
        <ButtonDashboard
          onClick={onClick}
          btnType={btnType}
          width={width}
          withIcon={withIcon}
          active={active}
          color={color}
          bgColor={bgColor}
          align={align}
        >
          {withIcon && (
            <Icon
              width="24"
              height="24"
              btnType={btnType}
              active={active}
              icon={icon}
            >
              <use xlinkHref={`${sprite}#${icon}`}></use>
            </Icon>
          )}
          {text}
        </ButtonDashboard>
      )}

      {btnType === "ai-generator" && (
        <ButtonAiGenerator
          onClick={onClick}
          btnType={btnType}
          width={width}
          withIcon={withIcon}
          active={active}
          color={color}
          bgColor={bgColor}
          align={align}
        >
          {withIcon && (
            <Icon
              width="16"
              height="16"
              btnType={btnType}
              active={active}
              icon={icon}
            >
              <use xlinkHref={`${sprite}#${icon}`}></use>
            </Icon>
          )}
          {text}
        </ButtonAiGenerator>
      )}
    </ButtonWrap>
  );
};

export default Button;
