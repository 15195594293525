import styled from "styled-components";
import { c2, h5, h6, yellow } from "../../assets";

export const Title = styled.div`
  ${h5}
  font-weight: 500;
  margin-right: 16px;
  margin-bottom: 48px;
  @media (max-width: 1024px) {
    ${h6}
    margin-bottom: 24px;
  }
  @media (max-width: 768px) {
    ${c2}
  }
`;

export const GalleryContainer = styled.div<{
  height: number | string;
  transitionHeight: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
  height: ${(props) => props.height};
  overflow: hidden;
  position: relative;
  transition: ${(props) =>
    props.transitionHeight ? "height 0.5s ease-in-out" : "none"};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const GalleryRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const GalleryItem = styled.div<{ span: number }>`
  flex: ${(props) => props.span};
  position: relative;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s linear;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b080ed8f;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s linear;

  ${GalleryItem}:hover & {
    opacity: 1;
  }
`;

export const PlayButton = styled.button`
  background-color: ${yellow};
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
`;

export const PlaySvg = styled.svg`
  width: 27px;
  height: 24px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: 0.3s linear;

  &:hover ${Overlay} {
    opacity: 1;
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  max-width: 90%;
  max-height: 80%;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
`;

export const VideoIframe = styled.iframe`
  width: 100%;
  height: 70vh;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 40px;
`;

export const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  color: black;
  border: none;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  z-index: 5;
`;
