import styled from "@emotion/styled";
import {
  yellow,
  violet,
  black,
  lightGray,
  white,
  whiteRGBA05,
  lavender,
} from "../../assets";
import {
  buttonText,
  dashboardButtonText,
  dashboardNavButtonText,
  buttonAiGenerator,
} from "../../assets/";

type ButtonProps = {
  btnType:
    | "primary"
    | "normal"
    | "dashboard"
    | "dashboard-nav"
    | "ai-generator";
  withIcon: boolean;
  width: string;
  active?: boolean;
  icon?: string;
  color?: string;
  bgColor?: string;
  align?: string;
};

type WrapProps = {
  disabled: boolean;
  margin: string;
};

type IconProps = {
  btnType?:
    | "primary"
    | "normal"
    | "dashboard"
    | "dashboard-nav"
    | "ai-generator";
  active?: boolean;
  icon?: string;
};

const BaseButtonStyles = `height: 64px;
border: none;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
gap: 5px;
border-radius: 12px;
transition: background-color 0.3s, transform 0.2s;
  transition: all 0.2s linear;
`;

export const ButtonWrap = styled.div<WrapProps>`
  ${(props) => props.disabled && `background-color: ${lightGray};`}

  margin-bottom: ${(props) => props.margin};
  border-radius: 12px;
  z-index: 1;
    
  @media (max-width: 327px) {
      width: 100%;
  }
`;

export const ButtonPrimary = styled.button<ButtonProps>`
  ${buttonText}
  ${BaseButtonStyles}
  height: 64px;
  width: ${(props) => props.width};
  flex-direction: ${(props) => props.align && props.align};

  background-color: ${(props) =>
    props.active ? violet : props.bgColor ? props.bgColor : yellow};

  color: ${black};

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    border: 1px solid ${violet};
  }

  &:active {
    color: ${white};
    background-color: ${violet};
    transform: translateY(1px);
  }

  &:disabled {
    background-color: ${whiteRGBA05};
    color: ${whiteRGBA05};
    border: none;
  }

  @media (max-width: 327px) {
      width: 100%;
  }
`;

export const ButtonNormal = styled.button<ButtonProps>`
  ${buttonText}
  ${BaseButtonStyles}
height: 64px;
  width: ${(props) => props.width};

  background-color: ${(props) => (props.active ? violet : white)};
  color: ${(props) => (props.active ? white : black)};

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    border: 1px solid ${violet};
  }

  &:active {
    background-color: ${violet};
  }

  &:focus {
    outline: none;
    box-shadow: 0 2px 2px 3px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: ${lightGray};
    color: ${whiteRGBA05};
    border: none;
  }
`;
export const ButtonDashboard = styled.button<ButtonProps>`
  ${(props) =>
    props.btnType === "dashboard"
      ? dashboardButtonText
      : dashboardNavButtonText}
  ${BaseButtonStyles}
  height: 48px;
  width: ${(props) => props.width};
  background-color: ${(props) => (props.active ? lavender : props.bgColor)};
  color: ${(props) => (props.active ? white : props.color)};
  font-weight: ${(props) => (props.active ? 600 : 400)};
  ${(props) =>
    props.align === "start"
      ? `justify-content: flex-start; padding-left: 16px;`
      : ""}

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;
export const ButtonAiGenerator = styled.button<ButtonProps>`
  ${(props) =>
    props.btnType === "dashboard"
      ? dashboardButtonText
      : dashboardNavButtonText}
  ${BaseButtonStyles}
  ${buttonAiGenerator}
  height: 36px;
  width: ${(props) => props.width};
  background-color: ${(props) => (props.active ? lavender : props.bgColor)};
  color: ${(props) => (props.active ? white : props.color)};
  font-weight: 500;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const Icon = styled.svg<IconProps>`
  ${(props) =>
    props.btnType === "dashboard" ||
    (props.btnType === "dashboard-nav" && props.active)
      ? `fill: ${white}; stroke: ${lavender}`
      : props.btnType === "ai-generator"
      ? "fill: transparent;"
      : `fill: ${white}; stroke: ${black}`}
  ${(props) => (props.icon === "logout" ? "font-weight:400;" : "")}
`;
