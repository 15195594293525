import styled from "@emotion/styled";
import {
  c1,
  c2,
  c3,
  d1,
  d2,
  d3,
  h2,
  h3,
  h6,
  oldPriceText,
  pink,
  violet,
} from "../../assets/";

export const Wrap = styled.div`
  padding-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 32px;
    padding-top: 130px;
  }
`;

export const Title = styled.h2`
  ${h2}
  margin-bottom:31px;
  @media (max-width: 1024px) {
    margin-right: 30px;
    ${h3}
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    ${h6}
  }
`;
export const AccentTitle = styled.p`
  ${c2}
  font-weight:600;
  color: ${violet};
  margin-bottom: 16px;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const TitleWrap = styled.div`
  position: relative;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
  }
`;

export const Svg = styled.svg`
  position: absolute;
  right: -20px;
  top: -25px;
  fill: ${pink};
  @media (max-width: 1024px) {
    top: -40px;
  }
  @media (max-width: 768px) {
    width: 40px;
    right: 0px;
  }
`;

export const SubTitle = styled.p`
  ${d3}
  color:${violet};
  font-weight: semibold;
  @media (max-width: 768px) {
    ${c3}
  }
`;

export const Description = styled.p`
  ${d3}
  margin-bottom:32px;
  @media (max-width: 768px) {
    ${c3}
  }
`;

export const Img = styled.img`
  max-width: 644px;
  width: 50%;
  height: 100%;
  margin-left: 50px;
  border-radius: 12px;
  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
    height: auto;
    max-height: 400px;
    margin-top: 32px;
    margin-left: 0px;
  }
`;
export const PriceWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
export const PriceText = styled.p`
  ${c1}
  @media (max-width: 768px) {
    ${c3}
  }
`;
export const PriceBlock = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  color: #333333;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const CurrentPrice = styled.p`
  ${c1}
  font-weight: 600;
`;
export const OldPrice = styled.p`
  ${oldPriceText}
  text-decoration: line-through;
`;
export const PriceAdditionalWrap = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const PriceDescription = styled.p`
  ${d2}
  font-weight:700;
  color: #878787;
`;
export const CustomPrice = styled.p`
  ${d1}
  font-weight:700;
  color: #333333;
`;
export const PriceRow = styled.p`
  display: flex;
  align-items: center;
  gap: 6px;
`;
