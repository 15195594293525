import React from "react";
import { Wrap, Text, Svg, Description } from "./ServcieIncItem.styled";
import sprite from "../../icons/symbol-defs.svg";

type ServiceIncItemProps = {
  title: string;
  description?: string;
  icon?: string;
  full: boolean;
};

const ServiceIncItem: React.FC<ServiceIncItemProps> = ({
  title,
  full,
  icon,
  description,
}) => {
  return (
    <Wrap>
      <div>
        <Svg className="" width="40" height="40">
          <use xlinkHref={`${sprite}#${icon ? icon : "check"}`} />
        </Svg>
        <Text full={full}>{title}</Text>
        <Description>{description}</Description>
      </div>
    </Wrap>
  );
};

export default ServiceIncItem;
